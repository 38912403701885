import React, {useCallback, useEffect, useState} from "react";
import HeaderNotificationMessages from "./header-notification/header-notification-messages";
import HeaderProfile from "./header-profile/header-profile";
import ApplicationItemModal
    from "../../applications/applications-index/applications-list/applications-item-modal/applications-item-modal";
import {useDispatch, useSelector} from "react-redux";
import {notificationData} from "../../../store/notofocations/headerNotificationSlice";
import {db} from "../../../firebase";
import HeaderNotification from "./header-notification/header-notification";
import {limitToLast, onValue, ref} from "firebase/database";
import {applicationsSingle} from "../../../store/applications/applications-single/applicationsSingleSlice";
import {currentApplicationServiceById} from "../../../store/applications/application-service-order/applicationServiceOrderSlice";

const RightMenu = () => {

    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);
    const [notificationFromFireBase, setNotificationFromFireBase] = useState({});
    const {user} = useSelector((state) => state.auth);
    //modal
    const {single} = useSelector((state) => state.applicationSingle);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [favorite, setFavorite] = useState(0);
    const [error, setError] = useState(null);
    const [currentModalInfo, setCurrentModalInfo] = useState(null);
    //set active tab
    const [activeTabKey, setActiveTabKey] = useState({
        tabName: 'tab1',
        serviceId: 0,
        applicationId: 0
    })

    const openServicesModal = () => {
        setIsServiceModalOpen(true)
    }
    const openModalFromNotification = useCallback(
        (id, type, orderID) => {
            const applicationId = id
            dispatch(applicationsSingle(id)).then((resp)=>{
                setCurrentModalInfo(resp.payload)

            });
            setIsModalOpen(true);
            let currentApplicationId = id;
            if (type === 'chat') {

            }
            if (type === 'order') {
                // dispatch(applicationServicesGetById({currentApplicationId})).then((response) => {
                // });
                    dispatch(currentApplicationServiceById({applicationId,orderID})).then((response)=>{
                })
            }
        },[])
    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentModalInfo(null)
    };
    const closeServicesModal = () => {
        setIsServiceModalOpen(false)
    }
    //myparkingdev  (dev)
    //myparkingprod  (prod)
    useEffect(() => {
        const notificationsRef = ref(db, `myparkingdev/notifications/users/${user.id}`, limitToLast(1));
        const unsubscribe = onValue(notificationsRef, (snapshot) => {
            // console.log(snapshot.val())
            setNotificationFromFireBase(snapshot.val());
            setError(null);
        }, (error) => {
            setError(error.message);
        });
        return () => {
            unsubscribe();
        };
    }, []);


    useEffect(() => {
        dispatch(notificationData()).then((resp) => {
            console.log(resp.payload?.notifications)
            setNotifications(resp.payload?.notifications)
        })
    }, [dispatch])

    // console.log(notificationFromFireBase)
    useEffect(() => {
        if (notificationFromFireBase) {
            setNotifications((prevNotifications) => {
                // Ensure prevNotifications is an array
                // const safePrevNotifications = Array.isArray(prevNotifications) ? prevNotifications : []

                // Combine and deduplicate notifications
                return [notificationFromFireBase, ...prevNotifications]

                // console.log(allNotifications)
                // const uniqueNotificationsMap = new Map()
                //
                // allNotifications.forEach(notification => {
                //     uniqueNotificationsMap.set(notification.id, notification)
                // })
                // return Array.from(uniqueNotificationsMap.values())
            })
        }
    }, [notificationFromFireBase])


    // console.log(notifications)


    return (
        <>
            <div className="header__user ms-auto d-flex align-items-center">
                <HeaderNotificationMessages
                    notifications={notifications}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                    setNotifications={setNotifications}
                />
                <HeaderNotification
                    notifications={notifications}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                    setNotifications={setNotifications}
                />
                <HeaderProfile />
            </div>
            {isModalOpen && currentModalInfo ?
            <ApplicationItemModal
                single={currentModalInfo}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                setFavorite={setFavorite}
                openServicesModal={openServicesModal}
                isServiceModalOpen={isServiceModalOpen}
                closeServicesModal={closeServicesModal}
                tab={activeTabKey}
            /> : ''
            }
        </>


    );
};

export default RightMenu;
